.react-datepicker {
  &-popper {
    z-index: 10;
  }

  &__day--keyboard-selected {
    color: black;
    background-color: white;
  }
}
