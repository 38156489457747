.slider {
  :global .slick-arrow {
    display: none !important;
  }

  :global .slick-list {
    overflow: hidden;
  }

  :global .slick-track {
    display: flex;
  }
}

.margins {
  :global .slick-slide {
    margin: 0 16px;
  }
}
