body {
  background-color: #f6f6f6 !important;
  color: #0B0B0B;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: rgba(88, 88, 88, 0.25);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #585858;
  border-radius: 8px;
}
