.reservations {
  &__drag-image {
    position: absolute;
    left: -9999px;
    padding: 8px;
    width: 50px;
    border-radius: 8px;
    border: 1px solid #585858;
    opacity: 0.5;
    font-size: 12px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  }
}
